import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link';
import remark from 'remark';
import remarkHTML from 'remark-html';

import Layout from '../components/Layout';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Back = styled(TransitionLink)`
  position: relative;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  border-radius: 24px;
  margin-top: 30px;
  line-height: 20px;
  padding: 5px 20px 7px;

  @media (min-width: 700px) {
    &:hover {
      background-color: var(--color-8080);
    }

    .body-color &:hover,
    .body-color-no-anim &:hover {
      background-color: var(--secondary);
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  padding: 0 20px;
  position: relative;
  opacity: ${({ mount, show }) => (mount && show ? 1 : 0)};
  flex-grow: 1;
  margin: 100px auto 120px;
`;

const Project = styled.div`
  margin-bottom: 35px;
  text-align: center;
  display: block;

  @media (min-width: 1000px) {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    margin-bottom: 20px;
    text-align: left;
  }
`;
const Video = styled.video`
  width: 100px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 10px;

  @media (min-width: 1000px) {
    height: auto;
    width: 65px;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
    margin-bottom: 0;
  }
`;
const TitleWrapper = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    align-items: flex-end;
    transform: translateX(${({ move }) => (move ? '80px' : '0px')});
    transition: transform 0.2s ease-in-out;
  }
`;
const Title = styled.a`
  font-size: 32px;
  line-height: 28px;
  margin-bottom: 10px;
  display: block;

  @media (min-width: 1000px) {
    font-size: 64px;
    line-height: 60px;
    margin-bottom: 0;
  }
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  opacity: 0.5;

  @media (min-width: 1000px) {
    font-size: 16px;
    line-height: 22px;
    padding-left: 15px;
    margin-bottom: -1px;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
  }
`;
const Category = styled.div``;

const MobileCat = styled.div``;

const Text = styled.div`
  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }
`;

const DateContainer = styled.div`
  font-size: 32px;
  line-height: 42px;
  transition: opacity 0.15s ease-in-out;
  opacity: ${({ active, hide1180, hide1350 }) => (active ? (hide1180 || hide1350 ? '0' : '1') : '0.35')};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 27px;

  @media (min-width: 1180px) {
    opacity: ${({ active, hide1350 }) => (active ?  (hide1350 ? '0' : '1') : '0.35')};
  }

  @media (min-width: 1350px) {
    opacity: ${({ active }) => (active ? '1' : '0.35')};
  }
`;

const Content = ({ data, mount, isNarrow, transitionStatus }) => {
  const [activeProject, setActiveProject] = useState(null);
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (mount) {
      toTop();
    }

    return () => {
      setShow(false);
    };
  }, [mount]);

  const toTop = () => {
    if (typeof window !== ' undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
        setShow(true);
      }, 100);
    }
  };

  return (
    <ContentWrapper mount={mount} show={show && transitionStatus !== 'exiting'}>
      {data.project.map((item, i) => {
        return (
          <Project key={item.url} onMouseEnter={() => setActiveProject(i)} onMouseLeave={() => setActiveProject(null)}>
            <Video
              autoPlay
              loop
              muted={true}
              defaultMuted
              playsInline
              poster={item.poster_dt.publicURL}
              show={activeProject === i}
            >
              <source src={item.video_dt_webm.publicURL} type='video/webm' />
              <source src={item.video_dt_mp4.publicURL} type='video/mp4' />
            </Video>
            <TitleWrapper move={activeProject === i}>
              <Title href={item.url} target='_blank'>
                {item.title}
              </Title>
              <Description show={activeProject === i}>
                <Category>{isNarrow && <MobileCat>{item.category}</MobileCat>}</Category>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: remark()
                      .use(remarkHTML)
                      .processSync(item.description)
                      .toString()
                      .replace('href="', 'target="_blank" href="'),
                  }}
                ></Text>
              </Description>
            </TitleWrapper>
            {!isNarrow && (
              <DateContainer active={activeProject === i} hide1350={item.hide_1350} hide1180={item.hide_1180}>
                {item.category}
              </DateContainer>
            )}
          </Project>
        );
      })}
    </ContentWrapper>
  );
};

const ArchivePageContent = ({ data }) => {
  const { markdownRemark: post } = data;
  const isNarrow = useMediaQuery({ query: '(max-width: 1000px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

  return (
    <TransitionState>
      {({ mount, transitionStatus }) => {
        return (
          <Layout transitionStatus={transitionStatus} page='archive'>
            <Wrapper>
              <Back to='/'>Back</Back>
              <Content data={data} mount={mount} isNarrow={isNarrow} transitionStatus={transitionStatus} />
              <Footer isMobile={isMobile} mail={'hi@8080.studio'} invert='true' active='work' />
            </Wrapper>
          </Layout>
        );
      }}
    </TransitionState>
  );
};

const ArchivePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <ArchivePageContent data={frontmatter} />;
};

export default ArchivePage;

export const archivePageQuery = graphql`
  query ArchivePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        project {
          title
          url
          category
          description
          tech
          date
          hide_1350
          hide_1180
          poster_dt {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video_dt_mp4 {
            publicURL
          }
          video_dt_webm {
            publicURL
          }
        }
      }
    }
  }
`;
